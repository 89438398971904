import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

var DT = require("../images/dt01.png")

const IndexPage = () => {
  return (
    <div>
      <Layout>
        <Head title="Home" />
        <div class="grid grid-cols-6">
          <div class=" col-start-2 col-end-6">
            <h1 class=" font-bold">Who am I</h1>
            <div class=" ml-5 mt-8">
              <p class=" text-justify">
                I think of myself as an optimistic pessimist, a cross between
                Yoda and Toby Ziegler.
              </p>
              <p class="mt-2 text-justify">
                The five mantras I tend to live by:
                <ol class="list-decimal leading-tight ml-16 mt-2">
                  <li>"If you want peace,prepare for war." </li>
                  <li>"Treat others how you wish to be treated."</li>
                  <li>"If you live in shit, your work will be shit."</li>
                  <li>"Make love to your work."</li>
                  <li>"Do. Or do not. There is no try."</li>
                </ol>
              </p>
              <p class="mt-2 text-justify">
                Like every other human being, I am a walking contradiction. I do
                not trust people easily, but I will always go out of my way to
                help someone. I have faith in humanity, even though people can
                be inherently mean. (But that faith is being tested a lot
                lately). I am by no means a neat freak, but clean up after
                myself and want to leave things better than I found them.
              </p>
              <p class="mt-2 text-justify">
                As an individual with cerebral palsy, my racing mind does not
                always match my mouth or hands. Let alone all the ideas and
                thoughts that I try to express or create. But I do my best to
                act on them and not merely try. No matter how much I fail. "A
                person can better be judged on their failures than their
                success."
              </p>
              <p class="mt-2 text-justify">
                I am at my happiest discussing film, television shows, comic
                books, and video games. I enjoy trying to understand how
                something works, whether it be a story, app, video game, or
                device, and helping to contribute to making it better.
              </p>
              <p class="mt-2 text-justify">
                This Blog is a small representation of the thoughts and ideas
                that race around and explode in my head (I tend to call my best
                ones brain farts 🙂).
              </p>
              <img class="ml-64" src={DT} />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default IndexPage
